import React, { useState, useEffect } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import './Home.css'
import { useTranslation } from 'react-i18next'
import Header from '../components/Header'
import Button from '@mui/material/Button'
import Tlogo from '../components/Tlogo'
import { urlLogin } from '../util/States'
import { useDispatch, useSelector } from 'react-redux'
import { checkLoggedIn } from '../redux/isLoggedIn'

const Home = () => {
  const [open, setOpen] = React.useState(false)
  const { t, i18n } = useTranslation()
  const [language, setLanguage] = useState()
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const windowWidth = useSelector((state) => state.states.windowWidth)

  const dispatch = useDispatch()
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn)

  useEffect(() => {
    dispatch(checkLoggedIn())
  }, [dispatch])

  //change language when click on button
  const handleLanguage = () => {
    if (i18n.language === 'en') {
      i18n.changeLanguage('key')
      setLanguage('EN')
    } else {
      i18n.changeLanguage('en')
      setLanguage('SK')
    }
  }

  //on load page set language
  useEffect(() => {
    if (i18n.language === 'en') setLanguage('SK')
    else setLanguage('EN')
  }, [])

  const navigate = useNavigate()

  return (
    <>
      <div className="home-background">
        <div className="home-background-gradient">
          <Header windowWidth={windowWidth} isLoggedIn={isLoggedIn} />
          <Tlogo />
          <div className="home">
            <div className="home-container add">
              <div className="home-container-title-1">Try our app NOW!</div>
              <div className="home-container-box">
                {!isLoggedIn ? (
                  <>
                    <Button
                      className="home-container-box-button Login"
                      onClick={() => {
                        navigate('/login')
                      }}
                      variant="outlined"
                      size="large"
                    >
                      Log in
                    </Button>
                    <Button
                      className="home-container-box-button Login"
                      onClick={() => navigate('/toolbox')}
                      variant="outlined"
                      size="large"
                    >
                      Guest
                    </Button>
                    {/* <div className="home-container-box-guest" onClick={() => navigate('/toolbox')}>
                      You can also log in as guest.
                    </div> */}
                  </>
                ) : (
                  <Button
                    className="home-container-box-button Login"
                    onClick={() => {
                      navigate('/toolbox')
                    }}
                    variant="outlined"
                    size="large"
                  >
                    Start app
                  </Button>
                )}
              </div>
            </div>

            <div className="home-container">
              <div className="home-container-title-2">No more collisions.</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home
