import { createSlice } from '@reduxjs/toolkit'

const isTouringFromSession = sessionStorage.getItem('isTouring')
const initialIsTouring = isTouringFromSession !== null ? JSON.parse(isTouringFromSession) : true

const spaceWeather = createSlice({
  name: 'weather',
  initialState: {
    isTouring: initialIsTouring,
    isDragging: false,
    data: null,
    dateHighlight: 'LIVE',
    dateGroups: null,
    loading: { aia171_small: true, soho: true, enlil: true, ovation_small: true, jsons: true },
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload
    },
    setDateGroups: (state, action) => {
      state.dateGroups = action.payload
    },
    setIsTouring: (state, action) => {
      state.isTouring = action.payload
    },
    setIsDragging: (state, action) => {
      state.isDragging = action.payload
    },
    setDateHighlight: (state, action) => {
      state.dateHighlight = action.payload
    },
    setLoading: (state, action) => {
      state.loading = { ...state.loading, ...action.payload }
    },
  },
})

export const { setIsTouring, setIsDragging, setData, setDateHighlight, setDateGroups, setLoading } = spaceWeather.actions
export default spaceWeather.reducer
