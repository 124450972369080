import { createSlice } from '@reduxjs/toolkit'

const statesSlice = createSlice({
  name: 'states',
  initialState: {
    responsiveWindow: false,
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    isSettingsOpen: false,
  },
  reducers: {
    setResponsiveWindow: (state, action) => {
      state.responsiveWindow = action.payload
    },
    setWindowWidth: (state, action) => {
      state.windowWidth = action.payload
    },
    setWindowHeight: (state, action) => {
      state.windowHeight = action.payload
    },
    setIsSettingsOpen: (state, action) => {
      state.isSettingsOpen = action.payload
    },
  },
})

export const { setResponsiveWindow, setWindowWidth, setWindowHeight, setIsSettingsOpen } = statesSlice.actions
export default statesSlice.reducer
