import React, { useRef, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './SignUp.css'
import backgroundVideo from '../videos/scp-video2.mp4'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Header from '../components/Header'
import Autocomplete from '@mui/material/Autocomplete'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { urlLogin } from '../util/States'
import { useSelector } from 'react-redux'

function SignUp() {
  const navigate = useNavigate()

  const windowWidth = useSelector((state) => state.states.windowWidth)

  const [errorFromServer, setErrorFromServer] = useState(null)

  const countries = require('../datasets/codeCountries.json')
  const ValidationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters long')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+~`|}{[\]\\:';"<>?,./-]).+$/,
        'Must contain one uppercase, lowercase letter, number, and special character'
      ),
    country: Yup.string().required('Required'),
    firstname: Yup.string().required('Required'),
    lastname: Yup.string().required('Required'),
    organization: Yup.string().required('Required'),
    repassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm password is required'),
  })

  const formik = useFormik({
    initialValues: {
      country: '',
      organization: '',
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      repassword: '',
    },

    validationSchema: ValidationSchema,

    onSubmit: async (e) => {
      setErrorFromServer(null)
      const response = await fetch(urlLogin + 'signup', {
        method: 'post',
        headers: {
          // needed so express parser says the body is OK to read
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          country: v.country,
          organization: v.organization,
          firstname: v.firstname,
          lastname: v.lastname,
          email: v.email,
          password: v.password,
        }),
      })
      if (response.status !== 200) {
        // TODO: Add more detailed error handling.
        const error = await response.text()
        setErrorFromServer(error)
        return
      }
      navigate('/home')
    },
  })

  const e = formik.errors
  const v = formik.values
  const t = formik.touched

  return (
    <>
      {windowWidth > 800 && <video className="video" src={backgroundVideo} autoPlay loop muted disablePictureInPicture />}
      <Header windowWidth={windowWidth} />
      <div className="signup">
        <div className="signup-container">
          <div className="signup-container-box">
            <h5 className="signup-container-box-title">
              Create new account <div className="signup-container-box-title bluedot">.</div>
            </h5>
            <div className="signup-container-box-inputs">
              <div className="signup-container-box-inputs-row Country">
                <TextField
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={e.organization && t.organization}
                  helperText={e.organization && t.organization ? e.organization : null}
                  name="organization"
                  fullWidth
                  size="normal"
                  type="text"
                  className="signup-container-box-inputs-input Organization"
                  label="Organization"
                  variant="outlined"
                />
                <Autocomplete
                  className="signup-container-box-inputs-input Country"
                  options={countries}
                  autoHighlight
                  onBlur={() => formik.setFieldTouched('country', true)}
                  onChange={(e, value) => formik.setFieldValue('country', value ? value.label + ',' + value.code : '')}
                  name="country"
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{
                        '& > img': { mr: 2, flexShrink: 0 },
                        fontSize: '0.8rem',
                        backgroundColor: 'gray',
                      }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="15"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.label} ({option.code})
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={e.country && t.country}
                      helperText={e.country && t.country ? e.country : null}
                      label="Country"
                      inputProps={{
                        ...params.inputProps,

                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />{' '}
              </div>
              <div className="signup-container-box-inputs-row">
                <TextField
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={e.firstname && t.firstname}
                  helperText={e.firstname && t.firstname ? e.firstname : null}
                  name="firstname"
                  fullWidth
                  size="normal"
                  value={v.firstname}
                  type="text"
                  className="signup-container-box-inputs-input Firstname"
                  label="First name"
                  variant="outlined"
                />
                <TextField
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={e.lastname && t.lastname}
                  helperText={e.lastname && t.lastname ? e.lastname : null}
                  name="lastname"
                  fullWidth
                  size="normal"
                  value={v.lastname}
                  type="text"
                  className="signup-container-box-inputs-input Lastname"
                  label="Last name"
                  variant="outlined"
                />
              </div>
              <div className="signup-container-box-inputs-row">
                <TextField
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={e.email && t.email}
                  helperText={e.email && t.email ? e.email : null}
                  name="email"
                  fullWidth
                  size="normal"
                  value={v.email}
                  type="email"
                  className="signup-container-box-inputs-input Email"
                  label="Email"
                  variant="outlined"
                />
              </div>
              <div className="signup-container-box-inputs-row">
                <TextField
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={e.password && t.password}
                  helperText={e.password && t.password ? e.password : null}
                  name="password"
                  fullWidth
                  size="normal"
                  value={v.password}
                  type="password"
                  className="signup-container-box-inputs-input Password"
                  label="Passsword"
                  variant="outlined"
                />
              </div>
              <div className="signup-container-box-inputs-row">
                <TextField
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={e.repassword && t.repassword}
                  helperText={e.repassword && t.repassword ? e.repassword : null}
                  name="repassword"
                  fullWidth
                  size="normal"
                  value={v.repassword}
                  type="password"
                  className="signup-container-box-inputs-input Passwordrepeat"
                  label="Re-enter passsword"
                  variant="outlined"
                />
              </div>
              {errorFromServer ? <div className="signup-container-box-inputs-row-error">{errorFromServer}</div> : ''}
              <div className="signup-container-box-inputs-row Register">
                <Button
                  className="signup-container-box-inputs-button Register"
                  onClick={formik.handleSubmit}
                  variant="contained"
                  size="large"
                >
                  Register
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SignUp
