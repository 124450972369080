import React, { useEffect, useState } from 'react'
import { Route, Navigate } from 'react-router-dom'
import { urlLogin } from '../util/States'
import { useDispatch, useSelector } from 'react-redux'
import { checkLoggedIn } from '../redux/isLoggedIn'

const PrivateRoute = ({ componentToRender, admin }) => {
  const [isAdmin, setIsAdmin] = useState(null)
  const dispatch = useDispatch()

  const isLoggedIn = useSelector((state) => state.user.isLoggedIn)

  useEffect(() => {
    dispatch(checkLoggedIn())
  }, [dispatch])

  useEffect(() => {
    const checkIsAdmin = async () => {
      try {
        const response = await fetch(urlLogin + 'admin', {
          method: 'get',
          credentials: 'include',
          headers: {
            // needed so express parser says OK to read
            'Content-Type': 'application/json',
          },
        })
        const result = await response.json()
        if (result.isAdmin) {
          setIsAdmin(true)
        } else {
          setIsAdmin(false)
        }
      } catch (error) {
        console.error('Error checking admin status: ', error)
        setIsAdmin(false)
      }
    }
    const fetchData = async () => {
      await Promise.all([admin && checkIsAdmin()])
    }
    fetchData()
  }, [admin])

  if (admin) {
    if (isAdmin === null || isLoggedIn === null) {
      return <div style={{ color: 'white' }}>Loading...</div>
    } else if (isLoggedIn && isAdmin === true) {
      return componentToRender
    } else {
      return <Navigate to="/norights" />
    }
  } else {
    if (isLoggedIn === null) {
      return <div style={{ color: 'white' }}>Loading...</div>
    } else if (isLoggedIn === true) {
      return componentToRender
    } else {
      return <Navigate to="/login" />
    }
  }
}

export default PrivateRoute
