import './App.css'
import React, { Suspense, useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { Provider } from 'react-redux'
import store from './redux/store'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/sk'
import utcPlugin from 'dayjs/plugin/utc'
import dayjs from 'dayjs'
import SplashScreen from './pages/SplashScreen'
import LogIn from './pages/LogIn'
import SignUp from './pages/SignUp'
import NoRights from './pages/NoRights'
import Home from './pages/Home'
import PrivateRoute from './components/PrivateRoute'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { moduleNames } from './util/States.js'
import SatelliteHome from './pages/SatelliteHome.js'
import { interpolateCatmullRomSpline } from './util/helpersForOptimizedOrbits.js'
import Kozmickystrazca from './pages/KozmickyStrazca.js'
import DebrisClanok from './pages/DebrisClanok.js'
import PlanetaryClanok from './pages/PlanetaryClanok.js'
import WeatherHome from './pages/WeatherHome.js'
import { openDB } from 'idb'
import APP_VERSION from './version'

const checkVersionAndClearCache = async () => {
  const storedVersion = localStorage.getItem('appVersion')

  if (storedVersion !== APP_VERSION) {
    await clearAllIndexedDB()
    localStorage.setItem('appVersion', APP_VERSION)
    window.location.reload()
  }
}

const clearAllIndexedDB = async () => {
  const databases = await indexedDB.databases()
  databases.forEach((db) => {
    indexedDB.deleteDatabase(db.name)
  })
}
const Viewer = React.lazy(() => import('./pages/Viewer.js'))
const AsteroidHome = React.lazy(() => import('./pages/AsteroidHome.js'))

// Import the plugins
dayjs.extend(utc)
dayjs.extend(timezone)
library.add(fas)
dayjs.extend(utcPlugin)

export default function App() {
  useEffect(() => {
    checkVersionAndClearCache()
  }, [])
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'sk'}>
        <Provider store={store}>
          <Router basename="/">
            <Routes>
              <Route
                path="/planetarnaobrana/"
                element={
                  <Suspense fallback={<SplashScreen />}>
                    <AsteroidHome />
                  </Suspense>
                }
              />
              <Route
                path="/kozmickyodpad/"
                element={
                  <Suspense fallback={<SplashScreen />}>
                    <SatelliteHome />
                  </Suspense>
                }
              />
              <Route
                path="/kozmickepocasie/"
                element={
                  <Suspense fallback={<SplashScreen />}>
                    <WeatherHome />
                  </Suspense>
                }
              />
              <Route
                path="/kozmickepocasie/app"
                element={
                  <Suspense fallback={<SplashScreen />}>
                    <Viewer defaultModule={moduleNames.weather} disableOtherModules={true} />
                  </Suspense>
                }
              />
              <Route
                path="/planetarnaobrana/app"
                element={
                  <Suspense fallback={<SplashScreen />}>
                    <Viewer defaultModule={moduleNames.asteroid} disableOtherModules={true} />
                  </Suspense>
                }
              />
              <Route
                path="/kozmickyodpad/app"
                element={
                  <Suspense fallback={<SplashScreen />}>
                    <Viewer defaultModule={moduleNames.satellite} disableOtherModules={true} />
                  </Suspense>
                }
              />
              <Route
                path="/"
                element={
                  <Suspense fallback={<SplashScreen />}>
                    <Kozmickystrazca />
                  </Suspense>
                }
              />
              <Route
                path="/debrisClanok"
                element={
                  <Suspense fallback={<SplashScreen />}>
                    <DebrisClanok />
                  </Suspense>
                }
              />
              <Route
                path="/planetaryClanok"
                element={
                  <Suspense fallback={<SplashScreen />}>
                    <PlanetaryClanok />
                  </Suspense>
                }
              />
              <Route
                path="/toolbox"
                element={
                  <Suspense fallback={<SplashScreen />}>
                    <Viewer toolbox defaultModule={moduleNames.asteroid} disableOtherModules={false} />
                  </Suspense>
                }
              />

              <Route path="/signup" element={<PrivateRoute admin componentToRender={<SignUp />} />} />
              <Route path="/home" element={<Home />} />
              <Route path="/login" element={<LogIn />} />
              <Route path="/norights" element={<NoRights />} />
            </Routes>
          </Router>
        </Provider>
      </LocalizationProvider>
    </>
  )
}
