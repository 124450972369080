import React, { useEffect } from 'react'
import logo192 from './static/img/logo192.png'
import spaceLabLogo from './static/img/SPACE-LAB_logotyp.png'
import telekomLogo from './static/img/T_logo_carrier_surface_rgb_n.png'
import steamLogo from './static/img/steam.png'
import nocVyskumnikovLogo from './static/img/NV24_Logo_Biela_H.png'
import useDynamicBootstrapCSS from '../util/useDynamicBootstrapCSS'

const KozmickyStrazca = () => {
  useDynamicBootstrapCSS()

  return (
    <div className="main-page">
      <div className="container mx-auto">
        <div className="main-div border pt-3 pb-1">
          <div className="my-4 px-1">
            <h5 id="koz-main-h1" className="px-1 pt-1">
              KOZMICKÝ STRÁŽCA
            </h5>
          </div>
          <div className="my-5">
            <a
              href="/debrisClanok"
              id="odpad-btn"
              target="_blank"
              rel="noopener noreferrer"
              className="my-3 btn btn-lg w-75 py-4 btn-outline-light main-btn rounded-pill text-uppercase"
            >
              MONITOROVANIE KOZMICKÉHO ODPADU
            </a>
            <br />
            <a
              href="/planetaryClanok"
              id="plan-btn"
              target="_blank"
              rel="noopener noreferrer"
              className="my-3 btn btn-lg w-75 py-4 btn-outline-light main-btn rounded-pill text-uppercase"
            >
              PLANETÁRNA OBRANA
            </a>
            <br />
            <a
              href="/kozmickepocasie/"
              id="weather-btn"
              target="_blank"
              rel="noopener noreferrer"
              className="my-3 btn btn-lg w-75 py-4 btn-outline-light main-btn rounded-pill text-uppercase"
            >
              KOZMICKÉ POČASIE
            </a>
          </div>
          <div className="my-5 main-credits mx-auto text-center">
            <div className="mx-auto">
              <a
                href="http://www.space-lab.sk/"
                target="_blank"
                rel="noopener noreferrer"
                className="mx-2 align-self-center a-credit"
              >
                <img src={spaceLabLogo} alt="Space Lab" height="16em" />
              </a>
              <a
                href="https://www.deutschetelekomitsolutions.sk/"
                target="_blank"
                rel="noopener noreferrer"
                className="mx-2 a-credit"
              >
                <img src={telekomLogo} alt="Deutsche Telekom IT Solutions" height="30em" />
              </a>
              <a href="https://www.innovlab.sk" target="_blank" rel="noopener noreferrer" className="mx-2 a-credit">
                <img src={steamLogo} alt="Innovlab" height="35em" />
              </a>
              <a
                href="https://www.nocvyskumnikov.sk"
                target="_blank"
                rel="noopener noreferrer"
                className="mx-2 align-self-center pages-bright a-credit"
              >
                <img src={nocVyskumnikovLogo} alt="Noc Vyskumnikov" height="20em" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default KozmickyStrazca
