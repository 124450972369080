import React from 'react'
import './Tlogo.css'
import { basename } from '../util/States'

export default function Tlogo(props) {
  const { positionVertical } = props

  return (
    <div className={positionVertical === undefined ? 'Tlogo-container bottom' : 'Tlogo-container ' + positionVertical}>
      <img className="Tlogo-logo" src={basename + '/images/tcko.png'} />
    </div>
  )
}
