import { configureStore } from '@reduxjs/toolkit'
import counterReducer from './slice'
import userReducer from './isLoggedIn'
import preferencesReducer from './preferencesSlice'
import statesReducer from './statesSlice'
import longLatSlice from './longLatSlice'
import spaceWeather from './spaceWeather'

const store = configureStore({
  reducer: {
    counter: counterReducer,
    user: userReducer,
    preferences: preferencesReducer,
    states: statesReducer,
    longLat: longLatSlice,
    spaceWeather: spaceWeather,
  },
})

export default store
