import React from 'react'
import './SplashScreen.css'
import { basename } from '../util/States'

const SplashScreen = () => {
  return (
    <div class="splash-screen">
      <span class="splash-screen-nad">KOZMICKÝ STRÁŽCA 3</span>
      <div class="loader-container">
        <div class="loader"></div>
      </div>
      <h2>Načítavam dáta...</h2>
      <div class="splash-screen-home-logos">
        <img alt="Space Lab" class="splash-screen-space" src={basename + '/images/SPACE-LAB_logotyp.png'} />
        <img alt="T logo" src={basename + '/images/T_logo_carrier_surface_rgb_n.png'} />
        <img alt="innovlab" class="splash-screen-innovlab" src={basename + '/images/steam.png'} />
        <img alt="Noc vyskumnikov" src={basename + '/images/NV24_Logo_Biela_H.png'} />
      </div>
    </div>
  )
}

export default SplashScreen
