import React, { useEffect, useState } from 'react'
import backgroundVideo from '../videos/scp-video2.mp4'
import './LogIn.css'

import Header from '../components/Header'
import { useSelector } from 'react-redux'

function NoRights() {
  const windowWidth = useSelector((state) => state.states.windowWidth)

  return (
    <>
      {windowWidth > 800 && <video className="video" src={backgroundVideo} autoPlay loop muted disablePictureInPicture />}
      <Header windowWidth={windowWidth} />
      <div className="norights">
        <div className="norights-container">
          <div className="norights-container-box">
            <h5 className="norights-container-box-title">
              You have no rights for this page <div className="norights-container-box-title norights-bluedot">.</div>
            </h5>
          </div>
        </div>
      </div>
    </>
  )
}

export default NoRights
