import React from 'react'
import PO01Image from './static/img/PO01.png' // Update path as needed
import PO03Image from './static/img/PO03.png' // Update path as needed
import SPACE_LAB_logotyp from './static/img/SPACE-LAB_logotyp.png'
import T_logo_carrier_surface_rgb_n from './static/img/T_logo_carrier_surface_rgb_n.png'
import steam_white from './static/img/steam_white.png'
import logo_2x_webp from './static/img/NV24_Logo_Biela_H.png'
import license from './static/img/license.png' // Update path as needed
import samplePDFSK from './static/media/09-2023_eNV_VedeckyKurier_KozmickyStrazca-SK.pdf'
import samplePDFUA from './static/media/09-2023_eNV_VedeckyKurier_KozmickyStrazca-UA.pdf'
import useDynamicBootstrapCSS from '../util/useDynamicBootstrapCSS'

const PlanetaryClanok = () => {
  useDynamicBootstrapCSS()

  return (
    <>
      <div className="planetary-defense">
        <div className="container mx-auto planetary-defense">
          <a
            href="https://www.kozmickystrazca.sk"
            target="_blank"
            rel="noopener noreferrer"
            className="my-3 koz-str-div mx-auto px-3 bg-costume-pink font-weight-bold rounded-pill"
          >
            <h1 className="koz-str-h1 h1 px-3 font-weight-bolder h-costume-purple">KOZMICKÝ STRÁŽCA</h1>
          </a>

          <h1 id="mon-koz-odp" className="my-3 font-weight-lighter h-costume-purple text-uppercase">
            Planetárna <br />
            obrana
          </h1>

          <img src={PO01Image} className="img-fluid mx-auto d-block my-5 my_img" alt="PO01" />

          <div className="h5 font-weight-lighter text-dark text-center px-5 mx-3 nasa-zem text-uppercase">
            Naša Zem je ten najvzácnejší vesmírny objekt. Preto je potrebné ju neustále chrániť. Jedno z rizík, ktoré jej z
            vesmíru hrozí je zrážka s asteroidom. Nie je to však dôvod na obavy. Dnešné technológie sú schopné zabezpečiť, aby sme
            zrážke vedeli včas predísť.
          </div>

          <div className="row pt-3 text-justify table center-block text-dark">
            <div className="col-md-6 table">
              V našej slnečnej sústave objeha okolo Slnka okrem ôsmich planét aj množstvo objektov medziplanetárnej hmoty
              (planétky, kométy, asteroidy, meteoroidy a prachové častice). Tieto objekty môžu krížiť dráhu našej Zeme -
              najčastejšie menšie kamienky a prachové častice, ktoré môžeme pozorovať ako meteory (tzv. padajúce hviezdy).
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5 table">
              Môže však nastať situácia, aby sa naša Zem stretla aj s väčším telesom, ktoré by nezhorelo v zemskej atmosfére a
              bolo tak pre nás nebezpečné? Áno, občas. Preto sú potrebné pokročilé systémy, ktoré môžeme jednoducho nazvať
              ‘Kozmický strážca’.
            </div>
          </div>

          <div className="mt-3 mb-5 grad-purple">
            <div className="row p-2 p-3">
              <div className="col-2">
                <a
                  href="/planetarnaobrana/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="btn btn-outline-secondary btn-md border shadow-lg rounded my-btn my-2 white"
                  style={{ color: 'white' }}
                >
                  Vyskúšaj našu appku!
                </a>
              </div>
              <div className="col text_grad_box">
                Otvor si webovú aplikáciu, aby si získal predstavu, koľko potenciálne nebezpečných objektov sa priblíži k Zemi v
                najbližších desaťročiach. Dobrou správou je, že všetky minú našu Zem dostatočne ďaleko a žiadna zrážka nám
                nehrozí!
              </div>
            </div>
          </div>

          <img src={PO03Image} className="img-fluid mx-auto d-block mb-5 mt-1 my_img" alt="PO03" />

          <div class="my-5">
            <div class="mt-1 h2 font-weight-bolder h-costume-purple text-center text-uppercase">Príklad z praxe</div>
            <div class="row my-4">
              <div class="col-md-6">
                Zdá sa ti to celé ako sci-fi? Nie je to fikcia, celý tento proces sa už podarilo úspešne otestovať prostredníctvom
                misie{' '}
                <a target="_blank" href="https://dart.jhuapl.edu/Mission/">
                  DART
                </a>
                . Vďaka presným pozorovaniam a následným vypočtom bol stanovený cieľ misie - asteroid Dimorphos (priemer 150 m).
                Ten pre Zem nepredstavoval nebezpečenstvo, no obiehal okolo iného asteroidu (Didymos, priemer 760 m) a tak bol
                ideálnym kandidátom na otestovanie celej myšlienky odklonenia dráhy asteroidu. Minulý rok v semptembri (26.9.2022)
                halvná sonda misie DART narazila do asteroidu Dimorphos a skrátila tak jeho obežnú dobu okolo väčšieho asteroidu o
                33 minút! Budúci rok (2024) odštartuje ESA misia HERA, ktorá detailne preskúma vzniknutý impaktný kráter a získa
                tak dôležité detaily, aby sme túto
              </div>
              <div class="col-md-6">
                <p>technológiu mohli využiť kedykoľvek to budeme potrebovať.</p>
                <p>
                  Medziplanetárnym objektom sa odborne venujú experti aj na Slovensku. Astronómovia na Fakulte matematiky, fyziky
                  a informatiky, Univerzity Komenského v Bratislave a tiež na Astronomickom ústave, SAV, v.v.i. pozorujú a skúmajú
                  širokú škálu týchto objektov. Aj vďaka nim sa môžme na Zemi cítiť bezpečnejšie.
                </p>
                <p>
                  Slovensko sa v roku 2022 stalo pridruženým členom Európskej vesmírnej agentúry (ESA). Vďaka tomu môžeme aj na
                  Slovensku aktívne spolupracovať s ďalšími európskymi krajinami pri úsilí strážiť našu Zem pred hrozbami z
                  vesmíru.
                </p>
              </div>
              <div class="text-center font-italic">
                ZDROJ:{' '}
                <a target="_blank" href="https://www.esa.int/Space_Safety/Plans_for_the_future">
                  https://www.esa.int/Space_Safety/Plans_for_the_future
                </a>
              </div>
            </div>
          </div>

          <div className="my-5">
            <div className="mt-1 h2 font-weight-bolder h-costume-purple text-uppercase">ZAUJÍMAVOSŤ</div>
            <div class="mt-3 mb-5 px-4 py-2 pt-3 grad-purple">
              <div class="row table">
                <div class="col-md-6 text_grad_box">
                  Vedel si, že sa podarilo ku jednej kométe nie len doletieť, ale dokonca na nej aj úspešne pristáť? Bolo to v
                  roku 2014, keď pristávací modul ESA misie Rosetta pristál na kométe 67P/Churyumov-Gerasimenko. Na časti
                  procesora, ktorý zabezpečoval komunikáciu
                </div>
                <div class="col-md-6 text_grad_box px-4">
                  medzi sondou a pristávacím modulom pracovali aj experti z Košíc. Konkrétne v SPACE::LABe (na Oddelení kozmickej
                  fyziky, Ústav experimentálnej fyziky SAV, v.v.i.), kde sa vývoju a konštrukcii prístrojov pre vesmírne misie
                  venujú už desaťročia.
                </div>
              </div>
            </div>
          </div>

          <div className="my-5">
            <div className="my-2 h3 font-weight-bold">Kozmický Strážca - Planetárna Obrana PDF verzia</div>
            <a href={samplePDFSK} target="_blank" rel="noopener noreferrer" className="btn btn-outline-secondary my-btn m-2">
              Brožúra na stiahnutie
              <br />
              (SK verzia)
            </a>
            <a href={samplePDFUA} target="_blank" rel="noopener noreferrer" className="btn btn-outline-secondary my-btn m-2">
              Brožúra na stiahnutie
              <br />
              (UA verzia)
            </a>
          </div>

          <div className="my-5">
            <div className="mt-1 h2 font-weight-bold">Užitočné linky:</div>
            <div>
              <div>
                <b>ESA: </b>
                <a target="_blank" href="https://www.esa.int/Space_Safety/About_asteroids_and_Planetary_Defence ">
                  https://www.esa.int/Space_Safety/About_asteroids_and_Planetary_Defence
                </a>
              </div>
              <div>
                <b>NASA: </b>
                <a target="_blank" href="https://www.nasa.gov/planetarydefense/overview ">
                  https://www.nasa.gov/planetarydefense/overview
                </a>
              </div>
              <div>
                <b>IAU: </b>
                <a href="https://www.minorplanetcenter.net/ " target="_blank">
                  https://www.minorplanetcenter.net/
                </a>
              </div>
              <div>
                <b>Impact Earth: </b>
                <a href="https://impact.ese.ic.ac.uk/ImpactEarth/" target="_blank">
                  https://impact.ese.ic.ac.uk/ImpactEarth/
                </a>
              </div>
            </div>
          </div>

          <div className="my-5">
            <div className="mt-1 h2 font-weight-bold">Credits</div>
            <div className="d-flex flex-wrap">
              <a href="http://www.space-lab.sk/" target="_blank" className="mx-2 align-self-center">
                <img src={SPACE_LAB_logotyp} height="32em" alt="SPACE LAB Logo" />
              </a>
              <a href="https://www.deutschetelekomitsolutions.sk/" target="_blank" className="mx-2">
                <img src={T_logo_carrier_surface_rgb_n} height="60em" alt="Deutsche Telekom IT Solutions Logo" />
              </a>
              <a href="https://www.innovlab.sk" target="_blank" className="mx-2">
                <img src={steam_white} height="70em" alt="Innovlab Logo" />
              </a>
              <a href="https://www.nocvyskumnikov.sk" target="_blank" className="mx-2 align-self-center">
                <img src={logo_2x_webp} height="40em" style={{ filter: 'brightness(50%)' }} alt="Noc Vyskumnikov Logo" />
              </a>
            </div>
          </div>

          <div class="my-5">
            <div class="mt-1 h2 font-weight-bolder">Zdroj dát a vizualizácia:</div>
            <div>
              <div>
                <b>Pozičné dáta a atribúty asteroidov: </b>
                <a target="_blank" href="https://www.minorplanetcenter.net/">
                  https://www.minorplanetcenter.net/
                </a>
              </div>
              <div>
                <b>Atribúty planét: </b>
                <a target="_blank" href="https://nssdc.gsfc.nasa.gov/planetary/factsheet/">
                  https://nssdc.gsfc.nasa.gov/planetary/factsheet/
                </a>
              </div>
              <div>
                <b>Textúry planét: </b>
                <a href="https://www.solarsystemscope.com/" target="_blank">
                  https://www.solarsystemscope.com/
                </a>
              </div>
              <div>
                <b>Textúra asteroidu a satelitu DART: </b>
                <a href="https://nasa3d.arc.nasa.gov/" target="_blank">
                  https://nasa3d.arc.nasa.gov/
                </a>
              </div>
            </div>
          </div>
          <div className="my-5">
            <h5 className="mt-1 h2 font-weight-bold">Licencia</h5>
            <div className="mt-2">
              <p>
                Ak nie je uvedené inak, na obsah tohto dokumentu, aplikácie a zdrojového kódu sa vzťahuje licencia{' '}
                <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank" rel="noopener noreferrer">
                  Commons Attribution-NonCommercial-ShareAlike 4.0 International (CC BY-NC-SA 4.0)
                </a>
              </p>
              <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank" rel="noopener noreferrer">
                <img src={license} height="75em" style={{ filter: 'brightness(0%)' }} alt="License" />
              </a>
            </div>
          </div>
        </div>
        <footer>
          <div className="text-center p-3">
            <span className="text-footer">
              <a href="https://www.deutschetelekomitsolutions.sk/" target="_blank" rel="noopener noreferrer">
                <img src={T_logo_carrier_surface_rgb_n} height="20em" alt="Deutsche Telekom IT Solutions Logo" />
              </a>{' '}
              © 2023 Deutsche Telekom IT Solutions
            </span>
            <br />
            <a href="https://www.deutschetelekomitsolutions.sk/" target="_blank" rel="noopener noreferrer">
              https://www.deutschetelekomitsolutions.sk/
            </a>
          </div>
        </footer>
      </div>
    </>
  )
}

export default PlanetaryClanok
