import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './WeatherHome.css'
import { useTranslation } from 'react-i18next'
import backgroundVideo from '../videos/Background.mp4'
import ReadmoreModal from '../components/satellites/ReadmoreModal'
import { basename } from '../util/States'

export default function WeatherHome() {
  const [open, setOpen] = React.useState(false)
  const { t, i18n } = useTranslation()
  const [language, setLanguage] = useState()
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  //change language when click on button
  const handleLanguage = () => {
    if (i18n.language === 'en') {
      i18n.changeLanguage('key')
      setLanguage('EN')
    } else {
      i18n.changeLanguage('en')
      setLanguage('SK')
    }
  }

  //on load page set language
  useEffect(() => {
    if (i18n.language === 'en') setLanguage('SK')
    else setLanguage('EN')
  }, [])

  return (
    <>
      <video className="asteroidhome-video" src={backgroundVideo} autoPlay loop muted disablePictureInPicture />
      <div className="home-body">
        <div className="home-name-section">
          <span className="nad">{t('KOZMICKÝ STRÁŽCA')}</span>
          <span className="pod">{t('Kozmické počasie')}</span>
        </div>
        <div className="home-button-section">
          <div className="home-button-background">
            <Link to="/kozmickepocasie/app" style={{ textDecoration: 'none' }}>
              <div className="home-button">{t('SPUSTIŤ')}</div>
            </Link>
            <Link to="" style={{ textDecoration: 'none' }}>
              <div className="home-button" onClick={() => console.log('readmmore')}>
                {t('ČÍTAJ VIAC')}
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="home-logos">
        <img alt="Univerzita Komenskeho v Bratislave" src={basename + '/images/UK_Logo_s_textom_TP_horizontal.png'} />
        <img alt="SpaceLab" className="space" src={basename + '/images/SPACE-LAB_logotyp.png'} />
        <img alt="T logo" src={basename + '/images/T_logo_carrier_surface_rgb_n.png'} />
        <img alt="innovlab" className="innovlab" src={basename + '/images/output-onlinepngtools.png'} />
        <img alt="Noc vyskumnikov" src={basename + '/images/NV24_Logo_Biela_H.png'} />
      </div>
      <ReadmoreModal open={open} onClose={handleClose} />
      <div className="language-bar">
        <div className="language-button" onClick={handleLanguage}>
          {language}
        </div>
      </div>
    </>
  )
}
